// CAUTION: Do not edit this file. It has been generated automatically.
// NOTE: You can add more icons by editing config/fontawesome.yml and
// running `bin/fontawesome` after.
import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF as fabFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faLinkedin as fabLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faTwitter as fabTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faWordpress as fabWordpress } from '@fortawesome/free-brands-svg-icons/faWordpress'
import { faYoutube as fabYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'
import { faCalendarAlt as falCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt'
import { faCalendarCheck as falCalendarCheck } from '@fortawesome/pro-light-svg-icons/faCalendarCheck'
import { faCommentAltEdit as falCommentAltEdit } from '@fortawesome/pro-light-svg-icons/faCommentAltEdit'
import { faCommentAltLines as falCommentAltLines } from '@fortawesome/pro-light-svg-icons/faCommentAltLines'
import { faCopy as falCopy } from '@fortawesome/pro-light-svg-icons/faCopy'
import { faEdit as falEdit } from '@fortawesome/pro-light-svg-icons/faEdit'
import { faFileAlt as falFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt'
import { faFileCertificate as falFileCertificate } from '@fortawesome/pro-light-svg-icons/faFileCertificate'
import { faHeadSideBrain as falHeadSideBrain } from '@fortawesome/pro-light-svg-icons/faHeadSideBrain'
import { faMedal as falMedal } from '@fortawesome/pro-light-svg-icons/faMedal'
import { faPaperclip as falPaperclip } from '@fortawesome/pro-light-svg-icons/faPaperclip'
import { faThumbsDown as falThumbsDown } from '@fortawesome/pro-light-svg-icons/faThumbsDown'
import { faThumbsUp as falThumbsUp } from '@fortawesome/pro-light-svg-icons/faThumbsUp'
import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faUserHeadset as falUserHeadset } from '@fortawesome/pro-light-svg-icons/faUserHeadset'
import { faUsers as falUsers } from '@fortawesome/pro-light-svg-icons/faUsers'
import { faAngleDown as farAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown'
import { faAngleUp as farAngleUp } from '@fortawesome/pro-regular-svg-icons/faAngleUp'
import { faArrowRight as farArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight'
import { faCheck as farCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { faCheckCircle as farCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle'
import { faCheckSquare as farCheckSquare } from '@fortawesome/pro-regular-svg-icons/faCheckSquare'
import { faChevronDown as farChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import { faChevronRight as farChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faChevronUp as farChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp'
import { faCircle as farCircle } from '@fortawesome/pro-regular-svg-icons/faCircle'
import { faClipboard as farClipboard } from '@fortawesome/pro-regular-svg-icons/faClipboard'
import { faCopy as farCopy } from '@fortawesome/pro-regular-svg-icons/faCopy'
import { faCreditCard as farCreditCard } from '@fortawesome/pro-regular-svg-icons/faCreditCard'
import { faDotCircle as farDotCircle } from '@fortawesome/pro-regular-svg-icons/faDotCircle'
import { faEdit as farEdit } from '@fortawesome/pro-regular-svg-icons/faEdit'
import { faEllipsisV as farEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV'
import { faEnvelope as farEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import { faExclamationTriangle as farExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { faExternalLink as farExternalLink } from '@fortawesome/pro-regular-svg-icons/faExternalLink'
import { faEye as farEye } from '@fortawesome/pro-regular-svg-icons/faEye'
import { faFile as farFile } from '@fortawesome/pro-regular-svg-icons/faFile'
import { faHandPaper as farHandPaper } from '@fortawesome/pro-regular-svg-icons/faHandPaper'
import { faHourglass as farHourglass } from '@fortawesome/pro-regular-svg-icons/faHourglass'
import { faInfo as farInfo } from '@fortawesome/pro-regular-svg-icons/faInfo'
import { faLanguage as farLanguage } from '@fortawesome/pro-regular-svg-icons/faLanguage'
import { faLongArrowRight as farLongArrowRight } from '@fortawesome/pro-regular-svg-icons/faLongArrowRight'
import { faMagic as farMagic } from '@fortawesome/pro-regular-svg-icons/faMagic'
import { faReply as farReply } from '@fortawesome/pro-regular-svg-icons/faReply'
import { faSearch as farSearch } from '@fortawesome/pro-regular-svg-icons/faSearch'
import { faShield as farShield } from '@fortawesome/pro-regular-svg-icons/faShield'
import { faShieldCheck as farShieldCheck } from '@fortawesome/pro-regular-svg-icons/faShieldCheck'
import { faSpinner as farSpinner } from '@fortawesome/pro-regular-svg-icons/faSpinner'
import { faSquare as farSquare } from '@fortawesome/pro-regular-svg-icons/faSquare'
import { faStar as farStar } from '@fortawesome/pro-regular-svg-icons/faStar'
import { faStarHalf as farStarHalf } from '@fortawesome/pro-regular-svg-icons/faStarHalf'
import { faStarHalfStroke as farStarHalfStroke } from '@fortawesome/pro-regular-svg-icons/faStarHalfStroke'
import { faSync as farSync } from '@fortawesome/pro-regular-svg-icons/faSync'
import { faTimes as farTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { faTimesCircle as farTimesCircle } from '@fortawesome/pro-regular-svg-icons/faTimesCircle'
import { faTrashAlt as farTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt'
import { faUserPlus as farUserPlus } from '@fortawesome/pro-regular-svg-icons/faUserPlus'
import { faWindowClose as farWindowClose } from '@fortawesome/pro-regular-svg-icons/faWindowClose'
import { faAlarmClock as fasAlarmClock } from '@fortawesome/pro-solid-svg-icons/faAlarmClock'
import { faArrowRight as fasArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight'
import { faBars as fasBars } from '@fortawesome/pro-solid-svg-icons/faBars'
import { faCheck as fasCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faCheckCircle as fasCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faCircle as fasCircle } from '@fortawesome/pro-solid-svg-icons/faCircle'
import { faCloudDownload as fasCloudDownload } from '@fortawesome/pro-solid-svg-icons/faCloudDownload'
import { faCog as fasCog } from '@fortawesome/pro-solid-svg-icons/faCog'
import { faComment as fasComment } from '@fortawesome/pro-solid-svg-icons/faComment'
import { faComments as fasComments } from '@fortawesome/pro-solid-svg-icons/faComments'
import { faCommentsAlt as fasCommentsAlt } from '@fortawesome/pro-solid-svg-icons/faCommentsAlt'
import { faCopy as fasCopy } from '@fortawesome/pro-solid-svg-icons/faCopy'
import { faDotCircle as fasDotCircle } from '@fortawesome/pro-solid-svg-icons/faDotCircle'
import { faDownload as fasDownload } from '@fortawesome/pro-solid-svg-icons/faDownload'
import { faEdit as fasEdit } from '@fortawesome/pro-solid-svg-icons/faEdit'
import { faEnvelope as fasEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faEuroSign as fasEuroSign } from '@fortawesome/pro-solid-svg-icons/faEuroSign'
import { faExclamationTriangle as fasExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import { faExternalLinkAlt as fasExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons/faExternalLinkAlt'
import { faFile as fasFile } from '@fortawesome/pro-solid-svg-icons/faFile'
import { faFileAlt as fasFileAlt } from '@fortawesome/pro-solid-svg-icons/faFileAlt'
import { faGlobeAmericas as fasGlobeAmericas } from '@fortawesome/pro-solid-svg-icons/faGlobeAmericas'
import { faGlobeEurope as fasGlobeEurope } from '@fortawesome/pro-solid-svg-icons/faGlobeEurope'
import { faHeart as fasHeart } from '@fortawesome/pro-solid-svg-icons/faHeart'
import { faInbox as fasInbox } from '@fortawesome/pro-solid-svg-icons/faInbox'
import { faInfo as fasInfo } from '@fortawesome/pro-solid-svg-icons/faInfo'
import { faList as fasList } from '@fortawesome/pro-solid-svg-icons/faList'
import { faLock as fasLock } from '@fortawesome/pro-solid-svg-icons/faLock'
import { faLockAlt as fasLockAlt } from '@fortawesome/pro-solid-svg-icons/faLockAlt'
import { faMedal as fasMedal } from '@fortawesome/pro-solid-svg-icons/faMedal'
import { faMinus as fasMinus } from '@fortawesome/pro-solid-svg-icons/faMinus'
import { faPencilAlt as fasPencilAlt } from '@fortawesome/pro-solid-svg-icons/faPencilAlt'
import { faPhoneAlt as fasPhoneAlt } from '@fortawesome/pro-solid-svg-icons/faPhoneAlt'
import { faPlus as fasPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faPlusCircle as fasPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle'
import { faPowerOff as fasPowerOff } from '@fortawesome/pro-solid-svg-icons/faPowerOff'
import { faQuestion as fasQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion'
import { faQuestionCircle as fasQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle'
import { faRedoAlt as fasRedoAlt } from '@fortawesome/pro-solid-svg-icons/faRedoAlt'
import { faReply as fasReply } from '@fortawesome/pro-solid-svg-icons/faReply'
import { faSearch as fasSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import { faShieldAlt as fasShieldAlt } from '@fortawesome/pro-solid-svg-icons/faShieldAlt'
import { faSpinner as fasSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner'
import { faStar as fasStar } from '@fortawesome/pro-solid-svg-icons/faStar'
import { faStarHalf as fasStarHalf } from '@fortawesome/pro-solid-svg-icons/faStarHalf'
import { faStarHalfStroke as fasStarHalfStroke } from '@fortawesome/pro-solid-svg-icons/faStarHalfStroke'
import { faTasksAlt as fasTasksAlt } from '@fortawesome/pro-solid-svg-icons/faTasksAlt'
import { faTh as fasTh } from '@fortawesome/pro-solid-svg-icons/faTh'
import { faThumbsDown as fasThumbsDown } from '@fortawesome/pro-solid-svg-icons/faThumbsDown'
import { faThumbsUp as fasThumbsUp } from '@fortawesome/pro-solid-svg-icons/faThumbsUp'
import { faTimes as fasTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faTimesCircle as fasTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { faUser as fasUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { faUserCheck as fasUserCheck } from '@fortawesome/pro-solid-svg-icons/faUserCheck'
import { faUserMinus as fasUserMinus } from '@fortawesome/pro-solid-svg-icons/faUserMinus'
import { faUserPlus as fasUserPlus } from '@fortawesome/pro-solid-svg-icons/faUserPlus'
import { faWindowClose as fasWindowClose } from '@fortawesome/pro-solid-svg-icons/faWindowClose'
library.add(
  fabFacebookF,
  fabLinkedin,
  fabTwitter,
  fabWordpress,
  fabYoutube,
  falCalendarAlt,
  falCalendarCheck,
  falCommentAltEdit,
  falCommentAltLines,
  falCopy,
  falEdit,
  falFileAlt,
  falFileCertificate,
  falHeadSideBrain,
  falMedal,
  falPaperclip,
  falThumbsDown,
  falThumbsUp,
  falTimes,
  falUserHeadset,
  falUsers,
  farAngleDown,
  farAngleUp,
  farArrowRight,
  farCheck,
  farCheckCircle,
  farCheckSquare,
  farChevronDown,
  farChevronRight,
  farChevronUp,
  farCircle,
  farClipboard,
  farCopy,
  farCreditCard,
  farDotCircle,
  farEdit,
  farEllipsisV,
  farEnvelope,
  farExclamationTriangle,
  farExternalLink,
  farEye,
  farFile,
  farHandPaper,
  farHourglass,
  farInfo,
  farLanguage,
  farLongArrowRight,
  farMagic,
  farReply,
  farSearch,
  farShield,
  farShieldCheck,
  farSpinner,
  farSquare,
  farStar,
  farStarHalf,
  farStarHalfStroke,
  farSync,
  farTimes,
  farTimesCircle,
  farTrashAlt,
  farUserPlus,
  farWindowClose,
  fasAlarmClock,
  fasArrowRight,
  fasBars,
  fasCheck,
  fasCheckCircle,
  fasCircle,
  fasCloudDownload,
  fasCog,
  fasComment,
  fasComments,
  fasCommentsAlt,
  fasCopy,
  fasDotCircle,
  fasDownload,
  fasEdit,
  fasEnvelope,
  fasEuroSign,
  fasExclamationTriangle,
  fasExternalLinkAlt,
  fasFile,
  fasFileAlt,
  fasGlobeAmericas,
  fasGlobeEurope,
  fasHeart,
  fasInbox,
  fasInfo,
  fasList,
  fasLock,
  fasLockAlt,
  fasMedal,
  fasMinus,
  fasPencilAlt,
  fasPhoneAlt,
  fasPlus,
  fasPlusCircle,
  fasPowerOff,
  fasQuestion,
  fasQuestionCircle,
  fasRedoAlt,
  fasReply,
  fasSearch,
  fasShieldAlt,
  fasSpinner,
  fasStar,
  fasStarHalf,
  fasStarHalfStroke,
  fasTasksAlt,
  fasTh,
  fasThumbsDown,
  fasThumbsUp,
  fasTimes,
  fasTimesCircle,
  fasUser,
  fasUserCheck,
  fasUserMinus,
  fasUserPlus,
  fasWindowClose
)
const css = dom.css()
dom.watch()
dom.insertCss(css)
