// See also app/model/proposition.rb if you change way to count words in text
// here.

window.countWords = function (text) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(text, 'text/html')
  // eslint-disable-next-line no-useless-escape
  const wordSeparator = /[\s’'ʼ"“”«»\.-?!⁇⁈‼⁉…;:,#\{\}\[\]\(\)]+/

  const cleaned = doc.documentElement.textContent.replace(
    /([\u2300-\u2BFF]|[\uE000-\uF8FF]|[\uFE00-\uFE0F]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|\uD83E[\uDD10-\uDDFF])/g,
    ''
  )

  const words = $.grep(cleaned.split(wordSeparator), (element, index) => {
    return element !== ''
  })
  return words.length
}
