// This initializer will reload the page if the meta tag "turbo-cache-control"
// is present and its content is "no-cache" when user is doing a back forward
// navigation.
// Turbo could do this automatically, but it doesn't.
window.addEventListener('pageshow', event => {
  // If the page is being loaded from the cache, and the "turbo-cache-control"
  // meta tag is present with "no-cache" value, then we'll reload the page.
  if (event.persisted && document.querySelector("meta[name='turbo-cache-control']")?.content === 'no-cache') {
    // The page should not be cached, so we'll force a reload.
    window.location.reload()
  }
})
