window.setModalTitle = function (title) {
  $('#modal-window .modal-header .modal-title').html(title)
}
window.setModalContent = function (content, options = {}) {
  $('#modal-window .modal-body').html(content)

  if ($('#modal-window .modal-body .modal-title').length === 1) {
    $('#modal-window .modal-header .modal-title').html($('#modal-window .modal-body .modal-title').html())
    $('#modal-window .modal-body .modal-title').remove()
  }

  if (options.addCloseButton !== false) {
    const closeButton = '<button aria-hidden="true" class="btn btn-secondary mr-2" data-dismiss="modal">Annuler</button>'
    const modalFooter = $('#modal-window .modal-body').find('.modal-footer')
    if (modalFooter.length > 0) {
      modalFooter.prepend(closeButton)
    } else {
      $('#modal-window .modal-body').append('<div class="modal-footer">' + closeButton + '</div>')
    }
  }
}

window.openModal = function (content, options = {}) {
  window.setModalContent(content, options)
  $('#modal-window').modal('show')
}
